import { Box, Container } from "@mui/material";
import React from "react";

function Emperor() {
  return (
    <Box>
      <Container></Container>
    </Box>
  );
}

export default Emperor;
