import { createBrowserRouter } from "react-router-dom";
import Home from "./home/page";
import AppLayout from "./AppLayout";
import Emperor from "./projects/emperor/page";

const router = createBrowserRouter([
  {
    path: "/",
    element: <AppLayout />,

    children: [
      {
        path: "/",
        element: <AppLayout />,
        children: [
          {
            index: true,
            element: <Home />,
          },
          {
            path: "projects",
            children: [
              {
                path: "emperor",
                element: <Emperor />,
              },
            ],
          },
        ],
      },
    ],
  },
]);
export default router;
