import {
  Box,
  Button,
  Chip,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import "./home.css";
import { Link } from "react-router-dom";

function Home() {
  return (
    <Box>
      <Box mb={10}>
        <Container maxWidth="md">
          <Grid container direction="row-reverse" spacing={3} mt={10}>
            <Grid
              item
              xs={12}
              sm={4}
              flex={1}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Box sx={{ width: { xs: "45vmin", sm: "28vmin" } }}>
                <img
                  className="profile-image"
                  alt="ali alkorsani"
                  src={require("assets/profile-image-min.png")}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Typography
                sx={{
                  fontSize: { xs: "10vmin", sm: "6vmin" },
                  textAlign: { xs: "center", sm: "left" },
                }}
                fontWeight="bold"
                variant="h4"
                mb={1}
              >
                Hello, I'm Ali, a software engineer
              </Typography>
              <Typography
                mb={2}
                sx={{
                  textAlign: { xs: "center", sm: "left" },
                }}
              >
                I’m a software developer with more than 5 years of professional
                experience, specializing in full-stack web and mobile
                development .I can assist in all stages of software development
                lifecycle and deliver working software with clean architecture
                and code
              </Typography>
              <Stack
                direction="row"
                sx={{ justifyContent: { xs: "center", sm: "flex-start" } }}
              >
                <Button size="large" variant="contained">
                  Download Resume
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box bgcolor="ButtonFace" pt={5} pb={5}>
        <Container maxWidth="md">
          <Grid container spacing={2} gap={2} justifyContent="center">
            {[
              { logo: require("assets/nodejs-logo-min.png") },
              { logo: require("assets/react-logo-min.png") },
              { logo: require("assets/reactquery-logo-min.png") },
              { logo: require("assets/redux-logo-min.png") },
              { logo: require("assets/reactnative-logo-min.png") },
              { logo: require("assets/socketio-logo-min.png") },
              { logo: require("assets/webrtc-logo-min.png") },
              { logo: require("assets/qt-logo-min.png") },
              { logo: require("assets/mongodb-logo-min.png") },
              { logo: require("assets/sql-logo-min.png") },
              { logo: require("assets/nginx-logo-min.png") },
              { logo: require("assets/linux-logo-min.png") },
            ].map((item) => (
              <Grid item sx={{ height: { xs: "14vmin", sm: "9vmin" } }}>
                <img src={item.logo} className="skill-logo" alt="" />
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
      <Box pt={5} pb={5}>
        <Container maxWidth="md">
          <Typography mb={1}>Featured works</Typography>

          {[
            {
              title: "Emperor",
              type: "Desktop app",
              des: "Qt C++ and qml desktop app with sqlite database for Emperor Juice shop",
              year: "2023",
              image: require("assets/emperor-thumbnail-min.png"),
              path: "/projects/emperor",
            },
            {
              title: "Alwarith Chat",
              type: "Mobile app",
              des: "React Native mobile chat app with nodejs, socketio and mongodb backend",
              year: "2022",
              image: require("assets/alwarith-chat-thumbnail-min.png"),
            },
            {
              title: "Alwarith Student App",
              type: "Mobile app",
              des: "React Native mobile app with nodejs and mongodb backend ",
              year: "2022",
              image: require("assets/alwarith-studnet-thumbnail-min.png"),
            },
          ].map((item) => (
            <Grid container spacing={2} mb={4}>
              <Grid item xs={12} sm={4} md={3}>
                <img className="card-media" src={item.image} alt="" />
              </Grid>

              <Grid item xs={12} sm={8} md={9}>
                <Typography sx={{ fontSize: { xs: 30, sm: 20 } }} variant="h6">
                  {item.title}
                </Typography>
                <Stack direction="row" spacing={3} alignItems="center" mt={1}>
                  <Chip label={item.year} />
                  <Typography color="GrayText">{item.type}</Typography>
                </Stack>
                <Stack alignItems="flex-start" gap={1}>
                  <Typography
                    fontSize={{ sm: "2.6vmin", md: "2.5vmin" }}
                    mt={1}
                  >
                    {item.des}
                  </Typography>
                  {/* <Button
                    LinkComponent={Link}
                    to={item.path}
                    variant="outlined"
                  >
                    Read More
                  </Button> */}
                </Stack>
              </Grid>
            </Grid>
          ))}
        </Container>
      </Box>
    </Box>
  );
}

export default Home;
